import LayoutHome from "../../layouts/LayoutHome";

export default function TokenHomePage() {
  return (
    <LayoutHome background="bg-white">
      <div className="pt-16">
        {/* Intro */}
        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-center hidden md:flex items-center justify-center"
          style={{ backgroundImage: `url('/images/token/bg-1.webp')` }}
        >
        </section>
        <section
          className="w-full aspect-[9/16] bg-no-repeat bg-cover bg-center md:hidden flex items-center justify-center"
          style={{ backgroundImage: `url('/images/token/bg-mobile-1.webp')` }}
        >
        </section>

        {/* About */}
        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-center hidden md:flex items-center justify-center"
          style={{ backgroundImage: `url('/images/token/bg-2.webp')` }}
        >
        </section>
        <section
          className="w-full aspect-[9/16] bg-no-repeat bg-cover bg-center md:hidden flex items-center justify-center"
          style={{ backgroundImage: `url('/images/token/bg-mobile-2.webp')` }}
        >
        </section>

        {/* Partner */}
        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-center hidden md:flex items-center justify-center"
          style={{ backgroundImage: `url('/images/token/bg-3.webp')` }}
        >
        </section>
        <section
          className="w-full aspect-[9/16] bg-no-repeat bg-cover bg-center md:hidden flex items-center justify-center"
          style={{ backgroundImage: `url('/images/token/bg-mobile-3.webp')` }}
        >
        </section>

        {/* HQ */}
        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-center hidden md:flex items-center justify-center"
          style={{ backgroundImage: `url('/images/token/bg-4.webp')` }}
        >
        </section>
        <section
          className="w-full aspect-video bg-no-repeat bg-cover bg-center md:hidden flex items-center justify-center px-4">
          <img src="/images/token/bg-mobile-4.webp" alt="Ecosystem" className="w-full" />
        </section>
      </div>
    </LayoutHome>
  );
}
