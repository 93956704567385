import LayoutHome from "../../layouts/LayoutHome";
import CharacterDisplay from "../../partials/game/CharacterDisplay";
import GameFeature from "../../partials/game/GameFeature";

export default function GameHomePage() {
  return (
    <LayoutHome background="bg-white">
      <div className="pt-16">
        {/* Story */}
        <section
          className="md:hidden w-full aspect-[9/16] bg-no-repeat bg-cover bg-center flex items-start justify-center"
          style={{ backgroundImage: `url('/images/game/bg-mobile-story.webp')` }}
        >
          <div className="p-8 font-bold font-cursive text-center pt-12">
            Welcome to Khuga Rumble Arena! Unleash your claws in this action-packed multiplayer cat-fighting game with role-playing elements. Featuring five Khuga races, each with their own unique abilities and weapons, Khuga Rumble Arena offers various gameplay modes with distinct mechanics playable in solo, cooperative, and competitive modes. Win battles, earn rewards – both off-chain and on-chain!
          </div>
        </section>

        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-center hidden md:flex items-center justify-center"
          style={{ backgroundImage: `url('/images/game/bg-story.webp')` }}
        >
          <div className="max-w-lg px-4 w-full aspect-[5/3] mt-10 md:text-lg lg:text-2xl font-bold text-center font-cursive text-[#00b57f] flex items-center justify-center">
          Welcome to Khuga Rumble Arena! Unleash your claws in this action-packed multiplayer cat-fighting game with role-playing elements. Featuring five Khuga races, each with their own unique abilities and weapons, Khuga Rumble Arena offers various gameplay modes with distinct mechanics playable in solo, cooperative, and competitive modes. Win battles, earn rewards – both off-chain and on-chain!
          </div>
        </section>

        {/* All characters */}
        <section
          className="md:hidden w-full aspect-[9/16] bg-no-repeat bg-cover bg-center relative"
          style={{ backgroundImage: `url('/images/game/bg-mobile-5-character.webp')` }}
        >
          <div className="p-8 text-center font-cursive pt-[25%]">
            <h2 className="text-xl mb-2 font-bold text-black">PLAYABLE CHARACTER</h2>
            <p className="text-xs">
              There are 5 playable characters with different attributes, skills, and roles that can be utilized uniquely. Each character also has a set of weapons that align with their base role. Specifically for Khuga NFT holders, you can play character sets according to the NFTs you own.
            </p>
          </div>
        </section>

        <section
          className="hidden md:block w-full aspect-[10/7] bg-no-repeat bg-cover bg-center relative"
          style={{ backgroundImage: `url('/images/game/bg-5-character.webp')` }}
        >
          <div className="absolute top-[8%] left-[6%] max-w-xl px-4 w-full aspect-[5/3] mt-10 text-lg md:text-xl font-medium font-cursive text-black">
            <h2 className="text-4xl mb-2 font-bold">PLAYABLE CHARACTER</h2>
            <p>
              There are 5 playable characters with different attributes, skills, and roles that can be utilized uniquely. Each character also has a set of weapons that align with their base role. Specifically for Khuga NFT holders, you can play character sets according to the NFTs you own.
            </p>
          </div>
        </section>

        {/* Character Display */}
        <CharacterDisplay />

        {/* GameFeature */}
        <GameFeature />
      </div>
    </LayoutHome>
  );
}
