import { useState } from "react";

const gameMode1 = [
  {
    name: "Area Capture",
    image: "/images/game/feature/icon-area-capture.webp",
    desc: "Teams strive to seize areas, and the team with the highest number of captured areas at the game's end emerges as the winner.",
  },
  {
    name: "Death Match",
    image: "/images/game/feature/icon-death-match.webp",
    desc: "Cut to the chase: defeat opponents, rack up scores, and lead your team to victory!",
  },
  {
    name: "Stricker",
    image: "/images/game/feature/icon-stricker.webp",
    desc: "Play with the ball, fight, survive, and aim to get the ball into the opponent's goal.",
  },
  {
    name: "Boss Raid",
    image: "/images/game/feature/icon-boss-raid.webp",
    desc: "PvE: Team up with friends, strategize, and overcome challenging boss battles.",
  },
];

const gameMode2 = [
  {
    name: "Damage",
    image: "/images/game/feature/icon-damage.webp",
    desc: "How much damage the Khuga can do.",
  },
  {
    name: "ATK Speed",
    image: "/images/game/feature/icon-atk-speed.webp",
    desc: "How fast the Khuga can attack.",
  },
  {
    name: "HP",
    image: "/images/game/feature/icon-hp.webp",
    desc: "How many hits the Khuga can take before being knocked down.",
  },
  {
    name: "Range",
    image: "/images/game/feature/icon-range.webp",
    desc: "How far is the attack range.",
  },
  {
    name: "Speed",
    image: "/images/game/feature/icon-speed.webp",
    desc: "How fast the Khuga runs.",
  },
];

export default function GameFeature() {
  const [active1, setActive1] = useState(0);
  const [active2, setActive2] = useState(0);

  const handleNext1 = () => {
    if (active1 === gameMode1.length - 1) {
      return setActive1(0);
    }

    if (active1 < gameMode1.length) {
      return setActive1((prev) => prev + 1);
    }
  };

  const handlePrev1 = () => {
    if (active1 === 0) {
      return setActive1(gameMode1.length - 1);
    }

    if (active1 <= gameMode1.length) {
      return setActive1((prev) => prev - 1);
    }
  };

	const handleNext2 = () => {
    if (active2 === gameMode1.length - 1) {
      return setActive2(0);
    }

    if (active2 < gameMode1.length) {
      return setActive2((prev) => prev + 1);
    }
  };

  const handlePrev2 = () => {
    if (active2 === 0) {
      return setActive2(gameMode1.length - 1);
    }

    if (active2 <= gameMode1.length) {
      return setActive2((prev) => prev - 1);
    }
  };

  return (
    <section className="w-full aspect-[9/16] md:aspect-[10/7] bg-no-repeat bg-cover bg-center relative" style={{ backgroundImage: `url('/images/game/bg-feature.webp')` }}>
      {/* Display */}
      <div className="w-[80%] md:w-[45%] relative top-8 md:top-36 aspect-[10/6] mx-auto">
        <div className="w-full absolute inset-0 px-[8%] pt-[6%]">
          <img src="/images/game/feature/ss.webp" alt="" className="w-full" />
        </div>
        <div className="w-full h-full bg-contain bg-no-repeat bg-center relative" style={{ backgroundImage: `url('/images/game/feature/display.webp')` }}>
          <h2 className="font-bold text-black font-cursive text-lg sm:text-xl md:text-2xl lg:text-3xl pt-[1%] pl-[8%]">Game Preview</h2>

          <button className="absolute -left-3 top-[40%] hover:scale-105 active:scale-90 transition-all">
            <img src="/images/game/display/arrow-left.webp" alt="Arrow left" className="h-14" />
          </button>
          <button className="absolute -right-3 top-[40%] hover:scale-105 active:scale-90 transition-all">
            <img src="/images/game/display/arrow-right.webp" alt="Arrow right" className="h-14" />
          </button>
        </div>
      </div>

      {/* Game mode */}
      <div className="max-w-5xl px-8 md:px-0 mx-auto h-72 mt-12 md:mt-28 relative">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-16">
          {/* Left */}
          <div
            className="w-full aspect-[4/3] bg-contain bg-no-repeat bg-center relative"
            style={{ backgroundImage: `url('/images/game/feature/board-left.webp')` }}
          >
            <h3 className="font-bold font-cursive text-base sm:text-lg md:text-xl lg:text-2xl text-black mt-2 ml-2 md:mt-4 md:ml-4">GAME MODE</h3>
            <div className="absolute bottom-4 pl-[16%] pr-[10%] font-bold text-black h-[20%]">
              <p className="text-xs md:text-base">{gameMode1[active1].desc}</p>
            </div>

            <div className="flex items-center justify-center gap-2 mt-0 md:mt-2 ml-4 md:ml-8">
              <button className="hover:scale-105 active:scale-90 transition-all" onClick={handlePrev1}>
                <img src="/images/game/display/arrow-left.webp" alt="Arrow left" className="h-14" />
              </button>
              <div className="w-36 md:w-48 aspect-square flex items-center justify-center">
                <img src={gameMode1[active1].image} alt="" className="w-2/3 md:w-4/5" />
              </div>
              <button className="hover:scale-105 active:scale-90 transition-all" onClick={handleNext1}>
                <img src="/images/game/display/arrow-right.webp" alt="Arrow right" className="h-14" />
              </button>
            </div>
          </div>

          {/* Right */}
          <div
            className="w-full aspect-[4/3] bg-contain bg-no-repeat bg-center relative"
            style={{ backgroundImage: `url('/images/game/feature/board-right.webp')` }}
          >
            <h3 className="font-bold font-cursive text-base sm:text-lg md:text-xl lg:text-2xl text-black text-right mt-2 mr-2 md:mt-4 md:mr-4">STATS</h3>

            <div className="absolute bottom-4 pl-[16%] pr-[10%] font-bold text-black h-[20%]">
              <p className="text-xs md:text-base">{gameMode2[active2].desc}</p>
            </div>

            <div className="flex items-center justify-center gap-2 mt-0 md:mt-2 mr-4 md:mr-8">
              <button className="hover:scale-105 active:scale-90 transition-all" onClick={handlePrev2}>
                <img src="/images/game/display/arrow-left.webp" alt="Arrow left" className="h-14" />
              </button>
              <div className="w-36 md:w-48 aspect-square flex items-center justify-center">
                <img src={gameMode2[active2].image} alt="" className="w-2/3 md:w-4/5" />
              </div>
              <button className="hover:scale-105 active:scale-90 transition-all" onClick={handleNext2}>
                <img src="/images/game/display/arrow-right.webp" alt="Arrow right" className="h-14" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
