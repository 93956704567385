import LayoutHome from "../../layouts/LayoutHome";
import CharacterDisplay from "../../partials/game/CharacterDisplay";
import GameFeature from "../../partials/game/GameFeature";

export default function LabsHomePage() {
  return (
    <LayoutHome background="bg-white">
      <div className="pt-16">
        {/* Intro */}
        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-center hidden md:flex items-center justify-center"
          style={{ backgroundImage: `url('/images/labs/bg-intro.webp')` }}
        >
        </section>
        <section
          className="w-full aspect-[9/16] bg-no-repeat bg-cover bg-center md:hidden flex items-center justify-center"
          style={{ backgroundImage: `url('/images/labs/bg-mobile-intro.webp')` }}
        >
        </section>

        {/* About */}
        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-center hidden md:flex items-center justify-center"
          style={{ backgroundImage: `url('/images/labs/bg-about.webp')` }}
        >
        </section>
        <section
          className="w-full aspect-[9/16] bg-no-repeat bg-cover bg-center md:hidden flex items-center justify-center"
          style={{ backgroundImage: `url('/images/labs/bg-mobile-about.webp')` }}
        >
        </section>

        {/* Partner */}
        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-center hidden md:flex items-center justify-center"
          style={{ backgroundImage: `url('/images/labs/bg-partner.webp')` }}
        >
        </section>
        <section
          className="w-full aspect-[9/16] bg-no-repeat bg-cover bg-center md:hidden flex items-center justify-center"
          style={{ backgroundImage: `url('/images/labs/bg-mobile-partner.webp')` }}
        >
        </section>

        {/* HQ */}
        <section
          className="w-full aspect-[10/7] bg-no-repeat bg-cover bg-center hidden md:flex items-center justify-center"
          style={{ backgroundImage: `url('/images/labs/bg-hq.webp')` }}
        >
        </section>
        <section
          className="w-full aspect-[9/16] bg-no-repeat bg-cover bg-center md:hidden flex items-center justify-center"
          style={{ backgroundImage: `url('/images/labs/bg-mobile-hq.webp')` }}
        >
        </section>
      </div>
    </LayoutHome>
  );
}
