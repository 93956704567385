import React, { Suspense, lazy, useContext, useEffect } from "react";
import { Routes, Route, Navigate, useNavigate, useLocation } from "react-router-dom";

import "./css/main.css";

import { useAuthenticatedHttpClient } from "./utils/authenticated-api-call";
import routes from "./routes";
import MIUHolder from "./pages/MIUHolder";
import AdminAuth from "./pages/Admin/AdminAuth";
import { UserContext } from "./context/UserContext";
import DiscordCallback from "./pages/DiscordCallback";
import Whitelist from "./pages/Whitelist";
import MintPage from "./pages/MintPage";
import UnderMaintenancePage from "./pages/UnderMaintenancePage";
import WhitelistClosed from "./pages/WhitelistClosed";
import Marketplace from "./pages/Marketplace/Marketplace";
import MarketplaceProducts from "./pages/Marketplace/MarketplaceProducts";
import MarketplaceGarage from "./pages/Marketplace/MarketplaceGarage";
import MarketplaceProductDetail from "./pages/Marketplace/MarketplaceProductDetail";
import AccountDetail from "./pages/Marketplace/AccountDetail";
import MarketplaceCheckout from "./pages/Marketplace/MarketplaceCheckout";
import LoadingBar from "./partials/LoadingBar";
import GameHomePage from "./pages/Game/GameHomePage";
import LabsHomePage from "./pages/Labs/LabsHomePage";
import TokenHomePage from "./pages/Token/TokenHomePage";

// Import pages
const Auth = lazy(() => import("./pages/Auth"));
const LandingPage = lazy(() => import("./pages/LandingPage"));
const Gallery = lazy(() => import("./pages/Gallery"));
const ConnectWallet = lazy(() => import("./pages/ConnectWallet"));
const Roadmap = lazy(() => import("./pages/Roadmap"));
const About = lazy(() => import("./pages/About"));
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"));

function App() {
  const location = useLocation();

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]); // triggered on route change

  return (
    <>
      <Suspense fallback={<LoadingBar />}>
        <Routes>
          <Route exact path={`${process.env.PUBLIC_URL}/`} element={<LandingPage />} />
          <Route exact path={`${process.env.PUBLIC_URL}/connect`} element={<ConnectWallet />} />
          <Route exact path={`${process.env.PUBLIC_URL}/about`} element={<About />} />
          <Route exact path={`${process.env.PUBLIC_URL}/roadmap`} element={<Roadmap />} />
          <Route exact path={`${process.env.PUBLIC_URL}/gallery`} element={<Gallery />} />
          <Route exact path={`${process.env.PUBLIC_URL}/miu`} element={<MIUHolder />} />
          <Route exact path={`${process.env.PUBLIC_URL}/whitelist`} element={<WhitelistClosed />} />
          {/* <Route exact path={`${process.env.PUBLIC_URL}/mint`} element={<MintPage />} /> */}
          <Route exact path={`${process.env.PUBLIC_URL}/game`} element={<GameHomePage />} />
          <Route exact path={`${process.env.PUBLIC_URL}/labs`} element={<LabsHomePage />} />
          <Route exact path={`${process.env.PUBLIC_URL}/token`} element={<TokenHomePage />} />
          <Route exact path={`${process.env.PUBLIC_URL}/auth`} element={<Auth />} />
          <Route exact path={`${process.env.PUBLIC_URL}/admin`} element={<AdminAuth />} />
          <Route exact path={`${process.env.PUBLIC_URL}/api/discord/callback`} element={<DiscordCallback />} />

          {/* <Route exact path={`${process.env.PUBLIC_URL}/marketplace`} element={<Marketplace />} />
          <Route exact path={`${process.env.PUBLIC_URL}/marketplace/account`} element={<AccountDetail />} />
          <Route exact path={`${process.env.PUBLIC_URL}/marketplace/garage`} element={<MarketplaceGarage />} />
          <Route exact path={`${process.env.PUBLIC_URL}/marketplace/checkout`} element={<MarketplaceCheckout />} />
          <Route exact path={`${process.env.PUBLIC_URL}/marketplace/products`} element={<MarketplaceProducts />} />
          <Route exact path={`${process.env.PUBLIC_URL}/marketplace/products/:id`} element={<MarketplaceProductDetail />} /> */}

          {routes.map((item) => (
            <Route
              key={item.key}
              exact
              path={`${process.env.PUBLIC_URL}${item.route}`}
              element={<RequireAuth roles={item.roles}>{item.component}</RequireAuth>}
            />
          ))}
          <Route exact path="/*" element={<NotFoundPage />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;

function RequireAuth({ roles, children }) {
  const navigate = useNavigate();
  const { internalAPIClient } = useAuthenticatedHttpClient();
  const [currentUser] = useContext(UserContext);

  useEffect(async () => {
    // const beacon = await internalAPIClient.get(`/auth/beacon`).catch(async (e) => {
    //   // localStorage.removeItem("metamask_address");
    //   // localStorage.removeItem("x-auth-khuga");
    //   // localStorage.removeItem("x-user-khuga");
    //   // return navigate(`${process.env.PUBLIC_URL}/auth`);
    // });

    // if (beacon?.data?.status !== "success") {
    //   // localStorage.removeItem("metamask_address");
    //   // localStorage.removeItem("x-auth-khuga");
    //   // localStorage.removeItem("x-user-khuga");
    //   // return navigate(`${process.env.PUBLIC_URL}/auth`);
    // }

    if (currentUser !== undefined) {
      const isSuperAdmin = (currentUser?.roles[0] || "user") === "superadmin";

      if (
        (isSuperAdmin && !localStorage.getItem("x-auth-khuga")) ||
        (!isSuperAdmin && (!localStorage.getItem("x-auth-khuga") || !localStorage.getItem("metamask_address")))
      ) {
        localStorage.removeItem("metamask_address");
        localStorage.removeItem("x-auth-khuga");
        localStorage.removeItem("x-user-khuga");
        return navigate(`${process.env.PUBLIC_URL}/auth`);
      } else if (currentUser !== null && currentUser !== undefined) {
        const hasAccess = roles.includes(currentUser?.roles[0]);
        if (!hasAccess) {
          return navigate(`${process.env.PUBLIC_URL}/miawwhalla`);
        }
      }
    }
  }, [currentUser]);

  return children;
}
